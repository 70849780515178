<template>
  <div id="wrapper">
    <nav class="navbar bg-transparent position-absolute w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="navbar-brand bars" @click="changeScreen(0)">
          <i class="ph-arrow-left text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
        </div>

        <span class="navbar-left">
          <div class="navbar-brand">
            <span style="display: -webkit-inline-box;">
              <h4 style="margin-top: 0.2rem">
              <i class="ph-bell-ringing text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
              </h4>
            </span>
          </div>      
        </span>

        <div class="divider mt-4 d-none"></div>
      </div>
    </nav>

    <div class="backdrop" @click="close()"></div>

    <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;">
        <div class="card border-0">
            <div class="card-body mb-3">
                <h5 class="fw-bold text-center text-orange mb-3" style="letter-spacing: -0.2px">Payment Instructions</h5>
            
                <ul class="list-group list-group-flush">
                    <li class="list-group-item border-0"> <span class="me-1 fw-bold">&bull;</span> Go to Mpesa toolkit.</li>
                    <li class="list-group-item border-0"> <span class="me-1 fw-bold">&bull;</span> Choose Lipa na Mpesa option.</li>
                    <li class="list-group-item border-0"> <span class="me-1 fw-bold">&bull;</span> Select Buy Goods and Services and enter till no <span class="fw-bold text-orange">9519911</span>.</li>
                    <li class="list-group-item border-0"> <span class="me-1 fw-bold">&bull;</span> Put the amount.</li>
                    <li class="list-group-item border-0"> <span class="me-1 fw-bold">&bull;</span> Enter your M-PESA PIN and confirm the transaction.</li>
                </ul>
            </div>
        </div>

        <div class="card-footer border-0 pt-0 pb-4">
            <router-link :to="{name: 'Home'}" class="btn bg-orange btn-sm text-white w-100 fw-bold">
                Back Home <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
            </router-link>
        </div>
    </div>

    <div id="map" style="height: 100%"></div>       
  </div>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import mapFunctions from '../mixins/mapFunctions'

  export default {
    name: 'PaymentInstructions',
    mixins: [mapFunctions],   
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      }  
    }    
  }
</script>
